// ErrorPage.js
import React from 'react';
import errorLogo from './Logo-Renvic.png';

const ErrorPage = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#fff',
    },
    logo: {
      maxWidth: '150px',
      marginBottom: '20px',
    },
    header: {
      fontSize: '2rem',
      color: '#D32F2F',
      textAlign: 'center',
      marginBottom: '10px',
    },
    message: {
      fontSize: '1rem',
      color: '#555',
      maxWidth: '600px',
      textAlign: 'center',
      padding: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <img src={errorLogo} alt="Error" style={styles.logo} />
      <h1 style={styles.header}>Error en la Verificación</h1>
      <p style={styles.message}>
        Ocurrió un problema durante el proceso de verificación. Por favor, intenta de nuevo más tarde.
      </p>
    </div>
  );
};

export default ErrorPage;
