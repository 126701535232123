import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Redirect } from 'react-router-dom';
import Spinner from './Components/Spinner';
import SuccessPage from './Components/SuccessPage';
import ErrorPage from './Components/ErrorPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Spinner />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;