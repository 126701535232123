import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Spinner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const getParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      token: params.get("token"),
    };
  };

  useEffect(() => {
    const { token } = getParams(location.search);

    if (!token) {
      navigate("/error");
      return;
    }

    const verifyEndpoint = `${process.env.REACT_APP_RENVIC_API_URL}/api/v1/accounts/token/mail/validate?token=${token}`;

    fetch(verifyEndpoint, {
      method: "GET",
      headers: {
        accept: "text/plain",
      },
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        const randomDelay = Math.random() * 3000;
        setTimeout(() => {
          setIsLoading(false);
          if (jsonResponse.data) {
            window.location.href = jsonResponse.data;
          } else {
            navigate("/error");
          }
        }, randomDelay);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        navigate("/error");
      });
  }, [location.search]);

  const spinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  return (
    <div style={spinnerStyle}>
      {isLoading ? (
        <div className="loading-spinner"></div>
      ) : (
        <p>Redireccionando...</p>
      )}
    </div>
  );
};

export default Spinner;
