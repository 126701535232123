import React from 'react';
import logo from './Logo-Renvic.png';

const SuccessPage = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f7f7f7',
    },
    logo: {
      maxWidth: '150px',
      marginBottom: '20px',
    },
    header: {
      fontSize: '2rem',
      color: '#333',
      textAlign: 'center',
      marginBottom: '10px',
    },
    message: {
      fontSize: '1rem',
      color: '#555',
      maxWidth: '600px',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h1 style={styles.header}>Verificación Exitosa</h1>
      <p style={styles.message}>
        Tu cuenta ha sido verificada con éxito. Ahora puedes disfrutar de todas las funcionalidades de nuestra plataforma.
      </p>
    </div>
  );
};

export default SuccessPage;
